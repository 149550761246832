import React from 'react';

import { Navbar, Container } from 'react-bootstrap';

function Header() {
    return (
        <Navbar fixed="top" expand="lg" bg="primary">
            <Container>
                <Navbar.Brand style={{ color: '#ffffff', margin: 'auto' }}>
                    Ellipsis Health PHQ9 Alert
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
