// Login.js

import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import logo from '../../assets/logo.svg';

function Logout(props) {
    useEffect(() => {
        if (!props.location.state) {
            props.history.push({
                pathname: '/login'
            });
        }
    }, []);

    return (
        <div style={{ paddingTop: '60px' }} className="Login">
            <Card style={{ maxWidth: '350px', width: '100%', margin: 'auto' }}>
                <Card.Img
                    variant="top"
                    src={logo}
                    width="100"
                    height="100"
                    style={{ paddingTop: '20px', marginBottom: '20px' }}
                />
                <Card.Body
                    style={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <h5
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        You have successfully logged out from the
                        <br /> Ellipsis Health PHQ9 Alert page.
                        <br /> Please click on the button below if you want to
                        access again
                    </h5>

                    <Button
                        block
                        size="lg"
                        type="submit"
                        onClick={() =>
                            props.history.push({
                                pathname: '/login',
                                state: {
                                    id: props.location.state.id
                                }
                            })
                        }
                    >
                        Back to login
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Logout;
