// Login.js

import React from 'react';
import { Card, Badge } from 'react-bootstrap';
import logo from '../../assets/logo.svg';

function Unknown() {
    return (
        <div className="Login">
            <Card style={{ width: '30rem', margin: 'auto' }}>
                <Card.Img
                    variant="top"
                    src={logo}
                    width="100"
                    height="100"
                    style={{ paddingTop: '20px', marginBottom: '20px' }}
                />
                <Card.Body
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <h5
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        / not found
                        <br /> The page you are looking for does not exists.
                        <br /> We will be happy to assist you.
                        <br /> Please reach out to us at{' '}
                        <Badge variant="primary">
                            support@ellipsishealth.com
                        </Badge>
                    </h5>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Unknown;
