import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import logo from './logo.svg';
import './App.scss';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './components/Login/Login.js';
import Alert from './components/Alert/Alert.js';
import Unknown from './components/Unknown/Unknown';
import Logout from './components/Logout/Logout';

function App(props) {
    return (
        <Router>
            <div>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route exact path="/phq9/:token" component={Alert} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/logout" component={Logout} />
                    <Route path="/" component={Unknown} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
