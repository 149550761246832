// Login.js

import React, { useState, useEffect } from 'react';
import firebase, { config, isLoggedIn } from '../../config/firebase';
import { Button, Card, Spinner, ListGroup } from 'react-bootstrap';

import Header from '../Header/Header';

// import './Login.scss';

function Alert(props) {
    /* const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); */
    const [loading, setLoading] = useState(false);
    const [hasError, setError] = useState(null);
    const [expired, setExpired] = useState(false);
    const [data, setData] = useState(null);

    const [loadingNewToken, setLoadingNewToken] = useState(false);

    const getTokenInfo = (token) => {
        setLoading(true);
        const url = `https://us-central1-${config.projectId}.cloudfunctions.net/tokenAuth`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token })
        })
            .then((response) => {
                setLoading(false);
                // Examine and get  the text in the response
                response.json().then((res) => {
                    if (res.expired) {
                        setExpired(true);
                    } else if (res.hasError) {
                        setError(res.message);
                    } else {
                        setData(res.data);
                    }
                });
            })
            .catch((err) => {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        console.log('Log user out');
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });
                alert(
                    'Something wrong happened while returning the patient data. Please contact your provider or support@ellipsishealth.com'
                );
                props.history.push('/login');

                console.log('Error while fetching ', err);
            });
    };

    useEffect(() => {
        if (!isLoggedIn()) {
            props.history.push({
                pathname: '/login',
                state: {
                    id: props.match.params.token
                }
            });

            return;
        }

        getTokenInfo(props.match.params.token);
    }, []);

    const generateNewTokenInfo = (token) => {
        setLoadingNewToken(true);
        const url = `https://us-central1-${config.projectId}.cloudfunctions.net/tokenAuth`;
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token, refresh: true })
        })
            .then((response) => {
                setLoadingNewToken(false);
                console.log(response.status);
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        console.log('Log user out');
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });

                props.history.push('/login');
            })
            .catch((err) => {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        console.log('Log user out');
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });

                alert(
                    'Something wrong happened while generating the new link. Please contact your provider or write to support@ellipsishealth.com'
                );
                props.history.push('/login');

                console.log('Error while fetching ', err);
            });
    };

    if (loading) {
        return (
            <div>
                <Header />
                <Card
                    style={{ width: '25rem', margin: 'auto', marginTop: 100 }}
                >
                    <Card.Body style={{ margin: 'auto' }}>
                        <Spinner animation="border" variant="primary" />
                    </Card.Body>
                </Card>
            </div>
        );
    }

    if (expired) {
        return (
            <div>
                <Header />
                <Card
                    style={{ width: '25rem', margin: 'auto', marginTop: 100 }}
                >
                    <Card.Body style={{ margin: 'auto' }}>
                        <p>
                            Link expired, click the button below to request a
                            new one.
                        </p>
                        <Button
                            block
                            size="lg"
                            onClick={() =>
                                generateNewTokenInfo(props.match.params.token)
                            }
                            disabled={loadingNewToken}
                        >
                            {loadingNewToken ? (
                                <Spinner animation="border" variant="light" />
                            ) : (
                                'Generate'
                            )}
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    if (hasError) {
        return (
            <div>
                <Header />
                <Card
                    style={{ width: '25rem', margin: 'auto', marginTop: 100 }}
                >
                    <Card.Body style={{ margin: 'auto' }}>
                        <p
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {hasError}
                        </p>
                        <Button
                            block
                            size="lg"
                            onClick={() => {
                                firebase.auth().signOut();
                                props.history.push('/login');
                            }}
                        >
                            Logout
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        );
    }

    if (data) {
        return (
            <div>
                <Header />

                <Card
                    style={{
                        maxWidth: '350px',
                        width: '100%',
                        margin: 'auto',
                        marginTop: 100
                    }}
                >
                    <Card.Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 5
                        }}
                    >
                        <ListGroup style={{ width: '100%' }} key="1">
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>Name:</h6>
                                {`${data.name || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>Email:</h6>
                                {`${data.email || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>MRN:</h6>
                                {`${data.mrn || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>Phone:</h6>
                                {`${data.phone || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>PHQ9 Score:</h6>
                                {`${data.phq || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>PHQ9-Q9 Score:</h6>
                                {`${data.phq9Score || ''}`}
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center'
                                }}
                            >
                                <h6>Session Date:</h6>
                                {data.sessionDate ? `${data.sessionDate}` : 'n/a'}
                            </ListGroup.Item>
                        </ListGroup>

                        <Button
                            block
                            style={{ width: '50%', marginTop: '20px' }}
                            size="md"
                            onClick={() => {
                                firebase.auth().signOut();
                                props.history.push({
                                    pathname: '/logout',
                                    state: {
                                        id: props.match.params.token
                                    }
                                });
                            }}
                        >
                            Logout
                        </Button>
                    </Card.Body>
                </Card>
            </div>
        );
    }
    return (
        <div>
            <Header />
        </div>
    );
}

export default Alert;
