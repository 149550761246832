import firebase from 'firebase';

// Initialize Firebase
let config = {};
if (process.env.REACT_APP_ENV === 'prod') {
    config = {
        apiKey: 'AIzaSyAwayyXuRWkeUgC8D57oPQ1OkKK5eIhZgA',
        authDomain: 'ellipsisapp-prod.firebaseapp.com',
        databaseURL: 'https://ellipsisapp-prod.firebaseio.com',
        projectId: 'ellipsisapp-prod',
        storageBucket: 'ellipsisapp-prod.appspot.com',
        messagingSenderId: '441992481208',
        appId: '1:441992481208:web:4757837cc13dbf3c55674d',
        measurementId: 'G-W0JZ5DHBHF'
    };
} else if (process.env.REACT_APP_ENV === 'test') {
    config = {
        apiKey: 'AIzaSyCszDcIOsK7-xdMCgkDKq6HUkCN_TJPhgo',
        authDomain: 'ellipsisapp-test.firebaseapp.com',
        databaseURL: 'https://ellipsisapp-test.firebaseio.com',
        projectId: 'ellipsisapp-test',
        storageBucket: 'ellipsisapp-test.appspot.com',
        messagingSenderId: '296970955147',
        appId: '1:296970955147:web:eadbe6f447ea182a18678e',
        measurementId: 'G-KZ4XND7TZY'
    };
}

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

firebase.auth().onAuthStateChanged((user) => {
    user
        ? localStorage.setItem('currentUser', JSON.stringify(user))
        : localStorage.removeItem('currentUser');
});

const isLoggedIn = () => {
    const user = firebase.auth().currentUser;

    return user;
};

// export const auth = firebase.auth();
export default firebase;
export { config, isLoggedIn };
