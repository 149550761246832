// Login.js

import React, { useState } from 'react';
import firebase from '../../config/firebase';
import { Button, Form, Card, Spinner } from 'react-bootstrap';
import logo from '../../assets/logo.svg';

import './Login.scss';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    function validateForm() {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(String(email).toLowerCase())) {
            return email.length > 0 && password.length > 0;
        } else {
            return false;
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (props.location.state && props.location.state.id) {
            setLoading(true);
            firebase
                .auth()
                .signInWithEmailAndPassword(email.toLowerCase(), password)
                .then(() => {
                    /**
                     * TODO: Redirect to next page
                     */
                    setLoading(false);
                    /* firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        console.log('Log user out');
                    })
                    .catch((error) => {
                        console.log(error.message);
                    });*/
                    props.history.push(`/phq9/${props.location.state.id}`);
                })
                .catch((e) => {
                    alert(
                        'Sorry, we are experiencing issues during the login process. Please react out to your provider or contact support@ellipsishealth.com'
                    );
                    setLoading(false);
                });
        } else {
            alert(
                'Please, click on the link provided by email or text message'
            );
        }
    }

    return (
        <div style={{ paddingTop: '60px' }} className="Login">
            <Card
                style={{
                    maxWidth: '350px',
                    width: '100%',
                    margin: 'auto'
                }}
            >
                <Card.Img
                    variant="top"
                    src={logo}
                    width="100"
                    height="100"
                    style={{ paddingTop: '20px' }}
                />
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="email" size="lg">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                autoFocus
                                autoComplete="on"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="password" size="lg">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                value={password}
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                            />
                        </Form.Group>
                        <Button
                            block
                            size="lg"
                            disabled={!validateForm() || loading}
                            type="submit"
                        >
                            {loading ? (
                                <Spinner animation="border" variant="light" />
                            ) : (
                                'Login'
                            )}
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Login;
